export function bootstrap() {
  const scrollTopButton = document.getElementById('scrolltop-button') as HTMLLinkElement
  const rootElement = document.scrollingElement

  if (!scrollTopButton || !rootElement) {
    return
  }
   
  document.addEventListener("scroll", handleScroll)

  scrollTopButton.addEventListener('click', handleClick)

  function handleClick(e) {
    e.preventDefault()

    rootElement.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }
   
  function handleScroll() {
    if (rootElement.scrollTop > (rootElement.clientHeight * 0.5)) {
      scrollTopButton.classList.add('scrolltop-button--visible')
    } else {
      scrollTopButton.classList.remove('scrolltop-button--visible')
    }
  }
}
